<template>
  <div class="positionT0L0">
    <div class="app-form">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        labelAlign="left"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="邀请码标题" prop="title">
          <a-input
            v-model="form.title"
            placeholder="请输入邀请码标题"
            :disabled="statusDisabled"
          >
            <div slot="addonAfter">{{ form.title.length }}/45</div>
          </a-input>
        </a-form-model-item>
        <a-form-model-item
          label="选择类型"
          prop="type"
          class="edu-type"
          :class="{
            'has-error': isRegionCode && !form.agentUserId && form.type == 0,
          }"
        >
          <a-select
            v-model="form.type"
            placeholder="请选择类型"
            @change="typeChange"
            :disabled="disabled"
            style="width: 200px"
          >
            <a-select-option v-for="item in typeList" :key="item.key">{{
              item.label
            }}</a-select-option>
          </a-select>
          <template v-if="form.type == 0">
            <a-select
              v-model="form.agentUserId"
              placeholder="请选择管理代理"
              :disabled="disabled"
              style="width: 200px"
            >
              <a-select-option v-for="item in agentList" :key="item.labelNo">{{
                item.labelText
              }}</a-select-option>
            </a-select>
          </template>
          <div
            class="ant-form-explain"
            v-if="isRegionCode && !form.agentUserId && form.type == 0"
          >
            请选择管理代理
          </div>
        </a-form-model-item>
        <a-form-model-item label="批次名称" prop="name">
          <a-input
            v-model="form.name"
            placeholder="请输入批次名称"
            :disabled="statusDisabled"
          >
            <div slot="addonAfter">{{ form.name.length }}/45</div>
          </a-input>
        </a-form-model-item>
        <a-form-model-item
          label="邀请码数量"
          prop="quantity"
          class="edu-quantity"
        >
          <a-input-number
            :disabled="disabled"
            v-model="form.quantity"
            :min="1"
            :max="6000"
            placeholder="请输入邀请码数量（不大于6000）"
          />
        </a-form-model-item>
        <a-form-model-item
          label="有效时间"
          prop="startTime"
          class="date-picker"
        >
          <template v-if="this.$route.params.id == 0">
            <a-date-picker
              v-model="form.startTime"
              :disabled-date="disabledStartDate"
              show-time
              :format="dateFormat"
              placeholder="开始时间"
              @openChange="handleStartOpenChange"
            />
            <span class="date-picker-label">至</span>
            <a-date-picker
              v-model="form.endTime"
              :disabled-date="disabledEndDate"
              show-time
              :format="dateFormat"
              placeholder="结束时间"
              :open="endOpen"
              @openChange="handleEndOpenChange"
            />
          </template>
          <template v-else>
            <a-input v-model="form.startTime" disabled>
              <a-icon slot="suffix" type="calendar" />
            </a-input>
            <span class="date-picker-label">至</span>
            <a-input v-model="form.endTime" disabled>
              <a-icon slot="suffix" type="calendar" />
            </a-input>
          </template>
        </a-form-model-item>
        <a-form-model-item
          label="对应内容"
          class="product"
          :class="{ 'has-error': isCheckCouseNo }"
          required
        >
          <div class="product-box" v-if="mdl">
            <a-icon
              v-if="this.$route.params.id == 0"
              type="close-circle"
              :style="{ fontSize: '20px', color: '#9FA5AF' }"
              class="product-close"
              @click="closeHandle"
            />
            <div
              class="product-wrap flexbox justify middle"
              :class="{ bg: this.$route.params.id != 0 }"
            >
              <div class="left-wrap flex1 flexbox left">
                <div class="product-img">
                  <img
                    class="p-img"
                    v-if="mdl.coverUrl"
                    :src="mdl.coverUrl"
                    alt=""
                  />
                  <img
                    class="p-img"
                    v-if="mdl.coverImg"
                    :src="mdl.coverImg"
                    alt=""
                  />
                </div>
                <div class="product-info flex1">
                  <div style="height: 56px">
                    <div class="p-title more-t">{{ form.businessName }}</div>
                    <!-- <div class="p-price">￥{{ mdl.price }}</div> -->
                    <div
                      v-show="form.businessType == 1 && mdl.pricingType == 0"
                      class="p-price"
                    >
                      免费
                    </div>
                    <div
                      v-show="form.businessType == 1 && mdl.pricingType == 1"
                      class="p-price"
                    >
                      ￥{{ mdl.price }}
                    </div>
                    <div
                      v-show="form.businessType == 1 && mdl.pricingType == 2"
                      class="p-price"
                    >
                      不支持单独售卖
                    </div>
                    <div v-show="form.businessType == 2" class="p-price">
                      ￥{{ mdl.price }}
                    </div>
                  </div>
                  <div class="p-type">
                    商品类型：{{ form.businessType == 1 ? "课程" : "会员" }}
                  </div>
                </div>
              </div>
              <div class="right-wrap">
                <a-button
                  type="primary"
                  :disabled="disabled"
                  @click="addProduct"
                  >更换商品</a-button
                >
              </div>
            </div>
          </div>
          <a-button v-else type="primary" @click="addProduct"
            >添加商品</a-button
          >
          <div class="ant-form-explain" v-if="isCheckCouseNo">请添加商品</div>
        </a-form-model-item>
      </a-form-model>
    </div>
    <RadioModal
      v-if="visible"
      :modalVisible="visible"
      :tabType="form.businessType"
      :type="0"
      :isShowMember="true"
      :selectedRowKeys="selectedRowKeys"
      @cancel="handleCancel"
      @ok="handleOk"
    />
    <footer-tool-bar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="backClick">返回</a-button>
      <a-button type="primary" @click="keepClick" :loading="isDisabled"
        >保存</a-button
      >
    </footer-tool-bar>
  </div>
</template>

<script>
import RadioModal from "@/components/courseMember/RadioModal";
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
import {
  SaveCodeApi,
  AgentSelectListApi,
  CodeDetailApi,
  SaveInvitationCodeApi,
  UpdInvitationCodeApi,
} from "@/request/api/marketingCenter";
import { format } from "@/utils/tools";

export default {
  mixins: [baseMixin],
  components: {
    RadioModal,
    FooterToolBar,
  },
  data() {
    return {
      disabled: false, // 编辑时，不能编辑
      statusDisabled: false, // 详情（失效）时，不能编辑
      typeList: [
        { key: 1, label: "线上码" },
        { key: 0, label: "线下码" },
      ],
      agentList: [],
      isDisabled: false, // 保存的按钮是否禁用
      dateFormat: "YYYY-MM-DD HH:mm:ss",
      labelCol: { span: 2 },
      wrapperCol: { span: 10 },
      form: {
        id: this.$route.params.id != 0 ? this.$route.params.id : "",
        title: "",
        name: "",
        quantity: undefined,
        businessType: 1,
        businessNo: "",
        businessName: "",
        agentUserId: undefined,
        type: undefined,
        startTime: null,
        endTime: null,
      },
      rules: {
        title: [
          { required: true, message: "请输入邀请码标题", trigger: "blur" },
          { max: 45, message: "最多输入45个字符", trigger: "change" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        name: [
          { required: true, message: "请输入批次名称", trigger: "blur" },
          { max: 45, message: "最多输入45个字符", trigger: "change" },
        ],
        quantity: [
          { required: true, message: "请输入邀请码数量", trigger: "blur" },
        ],
        startTime: [{ required: true, message: "请选择开始时间和结束时间" }],
        courseNo: [
          {
            required: true,
            message: "请选择开始时间和结束时间",
            trigger: "change",
          },
        ],
      },
      endOpen: false, // 选择开始时间后，弹出选择结束时间的弹窗
      isCheckCouseNo: false, // 是否添加商品
      isRegionCode: false, // 是否选择代理
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      selectedRowKeys: [],
    };
  },
  watch: {
    mdl(newVal) {
      if (newVal == null || newVal == "") {
        this.isCheckCouseNo = true;
      } else {
        this.isCheckCouseNo = false;
      }
    },
  },
  created() {
    if (this.$route.params.id != 0) {
      this.CodeDetailApi();
      this.disabled = true;
    }
  },
  methods: {
    disabledStartDate(startValue) {
      const endValue = this.form.endTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    disabledEndDate(endValue) {
      const startValue = this.form.startTime;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    // 邀请码详情
    CodeDetailApi() {
      let id = this.$route.params.id;
      CodeDetailApi({ id: id }).then(({ code, data }) => {
        let formData = data;
        this.mdl = formData;
        // 邀请码失效， 全部不能编辑
        if (formData.status == 1) {
          this.statusDisabled = true;
        }
        this.form.title = formData.title;
        this.form.type = formData.type;
        this.form.name = formData.name;
        this.form.quantity = formData.quantity;
        this.form.startTime = formData.startTime;
        this.form.endTime = formData.endTime;
        this.form.businessType = formData.businessType;
        this.form.businessName = formData.businessName;
        this.form.businessNo = formData.businessNo;
        if (formData.type == 0) {
          this.AgentSelectListApi();
          this.form.agentUserId = formData.agentUserId;
        }
      });
    },
    // 代理列表
    AgentSelectListApi() {
      const that = this;
      AgentSelectListApi().then((res) => {
        if (res.code == 200) {
          that.agentList = res.data;
        }
      });
    },
    // 邀请码类型切换[0：线下 1:线上]
    typeChange(val) {
      if (val == 0) {
        this.AgentSelectListApi();
      } else {
        this.form.agentUserId = undefined;
      }
    },
    // 添加商品事件
    addProduct(businessType) {
      if (!this.mdl) {
        this.mdl = null;
      }
      this.visible = true;
    },
    // 弹窗中取消按钮事件
    handleCancel() {
      this.visible = false;
    },
    // 弹窗中确认按钮事件
    handleOk(selectedRowKeys, selectedRows, businessType) {
      this.form.businessType = Number(businessType);
      if (businessType == 1 && selectedRows.courseNo) {
        // 课程
        this.mdl = selectedRows;
        this.selectedRowKeys = selectedRowKeys;
        this.form.businessNo = selectedRows.courseNo;
        this.form.businessName = selectedRows.name;
      } else if (businessType == 2 && selectedRows.memberNo) {
        // 会员
        this.mdl = selectedRows;
        this.selectedRowKeys = selectedRowKeys;
        this.form.businessNo = selectedRows.memberNo;
        this.form.businessName = selectedRows.memberName;
      }
      this.visible = false;
    },
    // 清除商品信息
    closeHandle() {
      this.mdl = null;
      this.form.businessType = 1;
      this.form.businessNo = "";
      this.form.businessName = "";
      this.selectedRowKeys = [];
    },
    // 返回上一页
    backClick() {
      this.$router.push("/marketingCenter/inviteCode");
    },
    keepClick() {
      const that = this;
      that.isDisabled = true;
      setTimeout(() => {
        that.isDisabled = false;
      }, 3000);
      // 自定义验证添加商品规则
      if (that.mdl == null || that.mdl == "") {
        that.isCheckCouseNo = true;
      } else {
        that.isCheckCouseNo = false;
      }
      // 判断邀请码类型，选择线下码的要判断是否选择代理
      if (that.form.type == 0) {
        if (that.form.agentUserId == "" || that.form.agentUserId == undefined) {
          this.isRegionCode = true;
        }
      } else {
        // 因为框架原因 要先定义为 undefined ,在提交的时候改为空值
        that.form.agentUserId = "";
      }
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (
            that.isRegionCode &&
            !that.form.agentUserId &&
            that.form.type == 0
          ) {
            that.$message.error("请选择管理代理");
          } else if (!that.isCheckCouseNo) {
            let form = that.form;
            // 添加邀请码，需要格式化时间
            if (form.id == 0) {
              form.startTime = format(
                new Date(that.form.startTime._d),
                "yyyy-MM-dd HH:mm:ss"
              );
              form.endTime = format(
                new Date(that.form.endTime._d),
                "yyyy-MM-dd HH:mm:ss"
              );
            }
            if (this.$route.params.id != 0) {
              UpdInvitationCodeApi(form).then((res) => {
                if (res.code == 200) {
                  that.$message.success("编辑成功");
                  that.$router.push("/marketingCenter/inviteCode");
                }
                that.isDisabled = false;
              });
            } else {
              SaveInvitationCodeApi(form).then((res) => {
                if (res.code == 200) {
                  that.$message.success("新增成功");
                  that.$router.push("/marketingCenter/inviteCode");
                }
                that.isDisabled = false;
              });
            }
          }
        }
      });
    },
  },
  beforeDestroy() {
    sessionStorage.removeItem("formData");
  },
};
</script>

<style lang="less" scoped>
.date-picker-label {
  margin-left: 10px;
  margin-right: 10px;
}
.product-box {
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  .product-close {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
  .product-wrap {
    height: 86px;
    &.bg {
      background: #ebebeb;
    }
    .left-wrap {
      .product-img {
        margin-right: 10px;
        height: 86px;
        .p-img {
          height: 86px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      .product-info {
        flex-direction: column;
        height: 86px;
        .p-title {
          margin-top: 6px;
          font-size: 14px;
          color: #000;
        }
        .p-price {
          color: red;
        }
        .p-type {
          color: #9fa5af;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .right-wrap {
      margin-left: 24px;
      margin-right: 24px;
    }
  }
}
/deep/.product .ant-form-item-control {
  line-height: normal;
}
/deep/.edu-type .ant-select:nth-child(2) {
  margin-left: 20px;
}
/deep/.edu-quantity .ant-input-number {
  width: 100%;
}
/deep/.date-picker .ant-form-item-children {
  display: flex;
  align-items: center;
}
</style>
